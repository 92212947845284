//
// Audio Player
// --------------------------------------------------


.audio-player {
  --#{prefix}audio-player-theme: #{$primary};
  --seek-before-width: 0%;
  --volume-before-width: 100%;
  --buffered-width: 0%;

  display: flex;
  align-items: center;
  width: 100%;
}


// Play button

.ap-play-button {
  padding-top: .125rem;
  font: {
    family: "boxicons";
    size: $h4-font-size;
    weight: normal;
    style: normal;
  }
  &::before {
    content: "\ebbd";
  }
  &.ap-pause::before {
    content: "\ebae";
  }
}


// Volume button

.ap-volume-button + .dropdown-menu {
  left: 50% !important; // stylelint-disable-line declaration-no-important
  width: 2.125rem;
  min-width: 2.125rem;
  max-width: 2.125rem;
  height: 6.5rem;
  margin-left: -1.0625rem !important; // stylelint-disable-line declaration-no-important
}


// Seek and volume sliders

.ap-seek-slider,
.ap-volume-slider {
  position: relative;
  padding: 0;
  margin: 0;
  -webkit-appearance: none; // stylelint-disable-line property-no-vendor-prefix
  background: none;
  outline: none;

  &::before {
    position: absolute;
    left: 0;
    cursor: pointer;
    content: "";
  }
}

// Seek slider
.ap-seek-slider {
  width: 100%;
  height: 1.125rem;
}
.ap-seek-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: .25rem;
  cursor: pointer;
  background: linear-gradient(to right, $gray-400 var(--buffered-width), rgba($gray-300, .9) var(--buffered-width));
}
.ap-seek-slider::before {
  top: .4375rem;
  width: var(--seek-before-width);
  height: .25rem;
  background-color: var(--#{prefix}audio-player-theme);
}
.ap-seek-slider::-webkit-slider-thumb {
  position: relative;
  box-sizing: content-box;
  width: 1rem;
  height: 1rem;
  margin: -.375rem 0 0;
  -webkit-appearance: none; // stylelint-disable-line property-no-vendor-prefix
  cursor: pointer;
  background-color: var(--#{prefix}audio-player-theme);
  border-radius: 50%;
}
.ap-seek-slider:active::-webkit-slider-thumb {
  transform: scale(1.2);
}
.ap-seek-slider::-moz-range-track {
  width: 100%;
  height: .25rem;
  cursor: pointer;
  background: linear-gradient(to right, $gray-400 var(--buffered-width), rgba($gray-300, .9) var(--buffered-width));
}
.ap-seek-slider::-moz-range-progress {
  background-color: var(--#{prefix}audio-player-theme);
}
.ap-seek-slider::-moz-focus-outer {
  border: 0;
}
.ap-seek-slider::-moz-range-thumb {
  box-sizing: content-box;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  background-color: var(--#{prefix}audio-player-theme);
  border: 0;
  border-radius: 50%;
}
.ap-seek-slider:active::-moz-range-thumb {
  transform: scale(1.2);
}

// Volume slider
.ap-volume-slider {
  top: 1.925rem;
  right: 1.5rem;
  width: 5rem;
  height: .5625rem;
  transform: rotate(-90deg);
}
.ap-volume-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: .1875rem;
  cursor: pointer;
  background-color: var(--#{prefix}audio-player-theme);
}
.ap-volume-slider::before {
  top: .1875rem;
  width: var(--volume-before-width);
  height: .1875rem;
  background-color: $white;
}
.ap-volume-slider::-webkit-slider-thumb {
  position: relative;
  box-sizing: content-box;
  width: .5625rem;
  height: .5625rem;
  margin: -.1875rem 0 0;
  -webkit-appearance: none; // stylelint-disable-line property-no-vendor-prefix
  cursor: pointer;
  background-color: $white;
  border-radius: 50%;
}
.ap-volume-slider:active::-webkit-slider-thumb {
  transform: scale(1.2);
}
.ap-volume-slider::-moz-range-track {
  width: 100%;
  height: .1875rem;
  cursor: pointer;
  background-color: var(--#{prefix}audio-player-theme);
}
.ap-volume-slider::-moz-range-progress {
  background-color: $white;
}
.ap-volume-slider::-moz-focus-outer {
  border: 0;
}
.ap-volume-slider::-moz-range-thumb {
  box-sizing: content-box;
  width: .5625rem;
  height: .5625rem;
  cursor: pointer;
  background-color: $white;
  border: 0;
  border-radius: 50%;
}
.ap-volume-slider:active::-moz-range-thumb {
  transform: scale(1.2);
}


// Dark color mode

@if $enable-dark-mode {
  @include color-mode(dark) {
    .audio-player:not([data-bs-theme="light"]) {
      .ap-seek-slider::-webkit-slider-runnable-track {
        background: linear-gradient(to right, rgba($white, .55) var(--buffered-width), rgba($white, .3) var(--buffered-width));
      }
    }
  }
}
