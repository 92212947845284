//
// Gallery
// based on https://github.com/sachinchoolur/lightgallery.js
// --------------------------------------------------


.gallery {
  --#{$prefix}gallery-overlay-bg: #{$gallery-overlay-bg};
  --#{$prefix}gallery-indicator-size: #{$gallery-indicator-size};
  --#{$prefix}gallery-image-indicator-color: #{$gallery-image-indicator-color};
  --#{$prefix}gallery-image-indicator-font-size: #{$gallery-image-indicator-font-size};
  --#{$prefix}gallery-video-indicator-bg: #{$gallery-video-indicator-bg};
  --#{$prefix}gallery-video-indicator-color: #{$gallery-video-indicator-color};
  --#{$prefix}gallery-video-indicator-font-size: #{$gallery-video-indicator-font-size};
  --#{$prefix}gallery-video-indicator-box-shadow: #{$gallery-video-indicator-box-shadow};
  --#{$prefix}gallery-video-indicator-hover-bg: #{$gallery-video-indicator-hover-bg};
  --#{$prefix}gallery-video-indicator-hover-color: #{$gallery-video-indicator-hover-color};
  --#{$prefix}gallery-video-indicator-hover-box-shadow: #{$gallery-video-indicator-hover-box-shadow};


}
.gallery-item {
  position: relative;
  display: block;
  overflow: hidden;
  text-decoration: none !important; // stylelint-disable-line declaration-no-important
  -webkit-mask-image: -webkit-radial-gradient($white, $black); // stylelint-disable-line property-no-vendor-prefix, value-no-vendor-prefix

  > img {
    display: block;
    width: 100%;
  }

  // Overlay
  &::before {
    position: absolute;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    background-color: var(--#{$prefix}gallery-overlay-bg);
    opacity: 0;
    transition: opacity .3s ease-in-out;
  }

  // Indicator
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    display: block;
    width: var(--#{$prefix}gallery-indicator-size);
    height: var(--#{$prefix}gallery-indicator-size);
    margin: {
      top: calc(var(--#{$prefix}gallery-indicator-size) * -.5);
      left: calc(var(--#{$prefix}gallery-indicator-size) * -.5);
    }
    line-height: var(--#{$prefix}gallery-indicator-size);
    color: var(--#{$prefix}gallery-image-indicator-color);
    text-align: center;
    font: {
      family: "boxicons";
      size: var(--#{$prefix}gallery-image-indicator-font-size);
    }
    content: "\ec90";
    transform: scale(.3);
  }

  // Item caption
  .gallery-item-caption {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 100%;
    padding: $spacer;
    color: $gallery-caption-color;
    transform: translateY(.5rem);
  }

  &::after,
  .gallery-item-caption {
    opacity: 0;
    transition: all .3s ease-in-out;
  }

  // Hover state
  &:hover,
  &.is-hovered {
    &::before,
    &::after,
    .gallery-item-caption {
      opacity: 1;
      transform: none;
    }
  }

  // Video item
  &.video-item {
    &::after {
      padding: {
        top: .0625rem;
        left: .25rem;
      }
      font-size: var(--#{$prefix}gallery-video-indicator-font-size);
      color: var(--#{$prefix}gallery-video-indicator-color);
      content: "\ebbd";
      background-color: var(--#{$prefix}gallery-video-indicator-bg);
      border-radius: 50%;
      opacity: 1;
      transform: none;
      @include box-shadow(var(--#{$prefix}gallery-video-indicator-box-shadow));
    }
    &:hover::after {
      color: var(--#{$prefix}gallery-video-indicator-hover-color);
      background-color: var(--#{$prefix}gallery-video-indicator-hover-bg);
      @include box-shadow(var(--#{$prefix}gallery-video-indicator-hover-box-shadow));
    }
  }
}
.lg-backdrop { z-index: $zindex-modal-backdrop + 5; }
.lg-outer {
  z-index: $zindex-modal + 5;
  .lg-thumb-item:hover,
  .lg-thumb-item.active {
    border-color: var(--#{$prefix}primary);
  }
}
.lg-on { overflow: hidden; }
.lg-item:focus,
.lg:focus,
.lg-outer:focus,
.lg-inner:focus { outline: none; }
