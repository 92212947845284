//
// Modal
// --------------------------------------------------


.modal-content {
  padding: {
    right: var(--#{$prefix}modal-padding);
    left: var(--#{$prefix}modal-padding);
  }
}

.modal-header,
.modal-footer {
  padding: {
    right: 0;
    left: 0;
  }
}

.modal-body {
  margin: {
    right: calc(var(--#{$prefix}modal-padding) * -1);
    left: calc(var(--#{$prefix}modal-padding) * -1);
  }
}


// Dark color mode

@if $enable-dark-mode {
  @include color-mode(dark) {
    .modal:not([data-bs-theme="light"]) {
      --#{$prefix}modal-bg: #{$modal-content-bg-dark};
      --#{$prefix}modal-border-width: #{$modal-content-border-width-dark};
    }
  }
}
